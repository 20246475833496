<template>
	<div class="pa-4">
		<div v-if="questionAnswers.length > 0">
			<v-row justify="center">
				<v-expansion-panels v-model="openedPanel" accordion>
					<v-expansion-panel
						v-for="(questionAnswer, i) in questionAnswers"
						:key="questionAnswer.id"
						class="pa-0"
					>
						<v-expansion-panel-header>
							<v-row no-gutters>
								<v-col
									cols="9"
									class="pr-3"
									style="text-align: start"
								>
									<h4>{{ questionAnswer.question }}</h4>
								</v-col>
								<v-spacer></v-spacer>
								<v-col
									cols="2"
									class="pr-3"
									style="text-align: start"
								>
									<StatusChips
										:is-published="
											questionAnswer.isPublished
										"
									></StatusChips>
								</v-col>
							</v-row>
							<template v-slot:actions>
								<v-icon color="var(--blue)">$expand</v-icon>
							</template></v-expansion-panel-header
						>
						<v-expansion-panel-content class="pt-4">
							<div v-html="questionAnswer.answer"></div>
						</v-expansion-panel-content>
						<v-divider></v-divider>
						<div
							class="px-6 py-4 card-actions d-flex"
							v-if="openedPanel === i"
						>
							<UnoEditButton
								:to="{
									name: 'question-answer.edit',
									params: { id: questionAnswer.id },
								}"
							></UnoEditButton>
							<UnoPublishButton
								:isPublished="questionAnswer.isPublished"
								@click.native="toggleStatus(questionAnswer)"
							></UnoPublishButton>
							<v-spacer></v-spacer>
							<UnoDeleteButton
								@confirm="deleteQuestion(questionAnswer.id)"
							></UnoDeleteButton>
						</div>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-row>
		</div>
		<NoData v-else></NoData>
	</div>
</template>

<script>
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'

import StatusChips from '@/components/StatusChips'
import NoData from '../../components/NoData'
export default {
	components: {
		UnoEditButton,
		UnoPublishButton,
		UnoDeleteButton,
		NoData,
		StatusChips,
	},
	data() {
		return {
			questionAnswers: [],
			openedPanel: null,
		}
	},
	created() {
		this.getQuestionAnswers()
	},
	methods: {
		getQuestionAnswers() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/question-answer?perPage=200`,
			})
				.then((res) => {
					if (res.status === 200) {
						this.questionAnswers = res.data.data
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		toggleStatus(questionAnswer) {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'post',
				url: `/${projectId}/question-answer/toggle-status/${questionAnswer.id}`,
			}).then((res) => {
				questionAnswer.isPublished = res.data.data.isPublished
			})
		},
		deleteQuestion(id) {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'delete',
				url: `/${projectId}/question-answer/${id}`,
			}).then((res) => {
				if (res.status === 200) this.getQuestionAnswers()
			})
		},
	},
}
</script>

<style></style>
